.card-details-wrapper {
  margin-bottom: 64px;

  @media (min-width: $breakpoint-md-2) {
    margin-bottom: 100px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-bottom: 120px;
  }

  @media (min-width: $breakpoint-xxl) {
    margin-bottom: 160px;
  }
}

.detail-info-tabs {
  .tabs-content {
    display: none;
    top: 88px;

    &.active {
      display: block;
      order: 1;
      width: 100%;
    }
  }
}

.description-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  line-height: 17.6px;

  @media (min-width: $breakpoint-md-2) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 20px;
    line-height: 25.14px;
    gap: 8px;
  }
}

.items-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  
  li {
    position: relative;
    font-size: 14px;
    line-height: 17.6px;
    font-weight: 400;
    padding-left: 15px;

    @media (min-width: $breakpoint-md-2) {
      font-size: 16px;
      line-height: 24px;
      padding-left: 20px;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 20px;
      line-height: 25.14px;
      padding-left: 20px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 8px;
      left: 0;
      top: 4px;
      background: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.51786 1.84993e-07L0.25 3.27835e-07L4.48214 5L0.25 10L3.51786 10L7.75 5L3.51786 1.84993e-07Z' fill='%2300A6EB'/%3E%3C/svg%3E%0A") no-repeat;
      background-size: cover;

      @media (min-width: $breakpoint-md-2) {
        width: 7.5px;
        height: 10px;
        top: 6px;
      }

      @media (min-width: $breakpoint-xl) {
        top: 7px;
      }
    }
  }
}

.tabs-list {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: $breakpoint-md-2) {
    gap: 8px;
  }
  
  @media (min-width: $breakpoint-xl) {
    gap: 16px;
  }
}

.tabs-list-item {
  position: relative;
  width: 100%;
  padding: 16px;
  margin-left: 0;
  border: 1px solid $grey-1;
  border-radius: 16px;
  
  @media (min-width: $breakpoint-xl) {
    padding: 24px;
  }
}

.tabs-list-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 20.8px;
  margin-bottom: 12px;

  @media (min-width: $breakpoint-md-2) {
    font-size: 18px;
    line-height: 23.4px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  @media (min-width: $breakpoint-xxl) {
    font-size: 24px;
    line-height: 31.2px;
  }
  
  .tabs-list-code {
    font-weight: 600;
  }
}

.tabs-list-result {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: $breakpoint-md-2) {
    gap: 16px;
  }
  
  @media (min-width: $breakpoint-xl) {
    flex-direction: row;
    align-items: center;
    gap: 44px;
  }
}

.tabs-list-wrapper {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media (min-width: $breakpoint-xl) {
    flex: 1;
  }

  .tabs-list-inner {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .tabs-list-value {
    font-size: 14px;
    line-height: 17.6px;
    white-space: nowrap;

    @media (min-width: $breakpoint-md-2) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .tabs-list-percentage-wrapper {
    flex: 1;
    background: $blue-5;
    border-radius: 16px;
    text-align: center;
    height: 24px;
    color: $white;

    .tabs-list-percentage {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $blue-1;
      border-radius: inherit;
      height: 100%;
      font-size: 14px;
      line-height: 17px;

      @media (min-width: $breakpoint-md-2) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.tabs-list-link {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.tabs-list-link-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-transform: uppercase;
  color: $base;

  @media (min-width: $breakpoint-md-2) {
    font-size: 16px;
    line-height: 24px;
  }
}

.tabs-list-link-line {
  flex: 1;
  height: 1px;
  background-color: $base;

  @media (min-width: $breakpoint-md-2) {
    flex: none;
    width: 113px;
  }

  @media (min-width: $breakpoint-xl) {
    width: 109px;
  }
}

.tabs-list-link-circle {
  position: relative;
  display: inline-block;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background: $blue-1;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s ease;
  cursor: pointer;
  
  &:before {
    content: "";
    background: url("data:image/svg+xml,%3Csvg width='9' height='12' viewBox='0 0 9 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.92143 -1.4028e-06L-2.38498e-07 -1.01322e-06L5.07857 6L9.53674e-07 12L3.92143 12L9 6L3.92143 -1.4028e-06Z' fill='white'/%3E%3C/svg%3E%0A") no-repeat;
    background-size: cover;
    width: 9px;
    height: 12px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
