.subjects {
  position: relative;
}

.subject-name {
  font-size: 12px;
  line-height: 15.08px;
  margin-bottom: 4px;
  display: block;

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
    line-height: 17.6px;
    margin-bottom: 2px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.no-triangle::-webkit-calendar-picker-indicator,
.no-triangle::-webkit-inner-spin-button {
  display: none;
}

.subject-number {
  display: block;
  height: 56px;
  width: 100%;
  padding: 16px;
  background: $white;
  border: 1px solid $grey-border;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  color: $base;
  -moz-appearance: textfield;

  &::placeholder {
    color: $grey-4;
  }

  &.is-invalid {
    color: $red-1;
    background: $red-2;
    border-color: $red-1;
  }

  &.is-empty {
    border-color: $red-1;
  }

  &:focus {
    border-color: $blue-1;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.select-content {
  position: relative;
}

.select-holder {
  --left-padding: 12px;
  --right-padding: 62px;
  
  .subject-select, .inputLabel {
    font-family: "source_sans_proregular", sans-serif !important;
    font-size: 16px;
    line-height: 24px;
    color: $base !important;
    letter-spacing: normal !important;
  }
  
  .inputLabel {
    max-width: calc(100% - var(--right-padding)) !important;
    white-space: break-spaces;
    transform: translateX(var(--left-padding));
    translate: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}

.subject-select {
  position: relative;
  width: 100%;
  background: $white;
  border: 1px solid $grey-border;
  border-radius: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  overflow: hidden;

  &.has-value {
    z-index: 10;
  }
  
  &.is-invalid {
    color: $red-1;
    background: $white;
    border-color: $red-1;

    .MuiSelect-select {
      background: $red-2;
    }
  }

  &.is-empty {
    border: 1px solid $error;
  }

  &:hover {
    border-color: $grey-4;
  }
  
  &.Mui-focused {
    border-color: $blue-1;
  }

  &:before, &:after {
    display: none;
  }

  .MuiSelect-select {
    display: flex;
    align-items: center;
    height: 56px !important;
    padding: 0 var(--right-padding) 0 12px !important;
    background: $white;
    
    &:after {
      content: "";
      position: absolute;
      right: 22px;
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      height: 10px;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9 9L17 1' stroke='%2300AEEF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      pointer-events: none;
      z-index: 10;
    }

    &[aria-expanded="true"]:after {
      transform: rotate(180deg) translateY(50%);
    }
  }
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .checkbox-box {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInput-input.MuiInputBase-input.css-1ar2bnf-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  .checkbox-box {
    display: none;
  }
}

.subject-error {
  position: absolute;
  left: 0;
  top: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  gap: 4px;
  color: $red-1;
  font-size: 8px;
  line-height: 12px;
  white-space: nowrap;
  margin-top: 2px;

  &::before {
    content: "";
    display: flex;
    width: 12px;
    height: 12px;
    background-image: url("../../../assets/img/error.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
  }

  &.hide-error-message {
    display: none;
  }

  &.select-error {
    position: relative;
    bottom: 8px;
  }
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
  font-family: "source_sans_proregular", sans-serif !important;
}

.MuiPaper-root {
  margin-top: 4px !important;
  transition-duration: 0s !important;
  box-shadow: none !important;
  border: 1px solid $grey-1 !important;
  border-radius: 8px !important;
  
  .MuiList-root {
    padding: 6px 8px;
    
    .MuiMenuItem-root {
      padding: 9px 4px;
      font-size: 14px;
      line-height: 17.6px;
      border-radius: 8px;
      
      &[aria-selected="true"] {
        background: $blue-2;
      }
    }
  }
}

#mui-component-select-checkbox {
  .checkbox-box {
    display: none;
  }
}

.subject-select-control {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 130px;
}

.form-fields, .fields-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-fields {
  margin-bottom: 24px;

  @media (min-width: $breakpoint-xl) {
    gap: 24px;
    margin-bottom: 32px;
  }
}

.fields-group {
  @media (min-width: $breakpoint-md-2) {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    gap: 12px;
  }
  
  @media (min-width: $breakpoint-xl) {
    gap: 20px;
  }
}