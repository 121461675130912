.header-form {
  .tabs {
    display: flex;
    align-items: center;

    .degree-controls & {
      flex: 1 1 500px;
    }
  }

  .tabs-item {
    position: relative;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 50%;
    text-align: center;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      right: 0;
      background-color: $grey;
      border-radius: 16px;

      .main & {
        width: 100%;
      }
    }

    &.is-active::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: -1px;
      right: 0;
      background-color: $accent;
      border-radius: 16px;

      .main & {
        width: 100%;
      }
    }

    &.is-active {
      color: $accent;
      font-weight: 600;
    }

    .degree-controls & {
      flex: 1 1 auto;
      height: 52px;
    }
  }

  .tabs-content {
    display: none;
  }

  .tabs-content.is-active {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
  }

  .tabs-content {
    form {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }
  }
}

.tabs {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  row-gap: 17px;

  @media (min-width: $breakpoint-md-2) {
    row-gap: 16px;
  }
  
  @media (min-width: $breakpoint-xl) {
    row-gap: 21px;
  }

  .degree-controls & {
    flex: 1 1 500px;
  }
}

.tabs-item {
  position: relative;
  flex: 1;
  font-size: 14px;
  line-height: 17.6px;
  padding-bottom: 9px;
  font-weight: 400;
  text-align: center;
  border-bottom: 2px solid $grey-3;
  white-space: nowrap;
  cursor: pointer;

  @media (min-width: $breakpoint-md-2) {
    padding: 0;
    font-size: 16px;
    line-height: 52px;
    height: 52px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 18px;
  }

  &:hover {
    color: $blue-1;
  }

  &:before {
    content: '';
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 4px;
    background: $blue-1;
    transform: translateY(-50%);
  }

  &.active {
    font-weight: 600;
    color: $blue-1;

    &:before {
      display: block;
    }
  }
}

.tabs-content {
  display: none;
}

.tabs-content.is-active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
}

.tabs-content {
  form {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}

.proposition-wrapper {
  margin-bottom: 64px;

  @media (min-width: $breakpoint-xl) {
    margin-bottom: 120px;
  }

  @media (min-width: $breakpoint-xxl) {
    margin-bottom: 160px;
  }
}
