.test-start-wrapper {
  padding: 24px 0 64px;

  @media (min-width: $breakpoint-md-2) {
    padding: 16px 0 100px;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 48px 0 120px;
  }

  @media (min-width: $breakpoint-xxl) {
    padding: 64px 0 160px;
  }
}

.test-start {
  margin-bottom: 64px;

  @media (min-width: $breakpoint-md-2) {
    display: flex;
    gap: 12px;
    margin-bottom: 104px;
  }
  
  @media (min-width: $breakpoint-xl) {
    gap: 8px;
    margin-bottom: 119px;
  }
  
  @media (min-width: $breakpoint-xxl) {
    gap: 36px;
    margin-bottom: 160px;
  }

  .test-start-holder {
    @media (min-width: $breakpoint-md-2) {
      flex: 1;

      .title-arrow {
        margin-left: 0;
      }
      
      .title {
        text-align: left;

        @media (min-width: $breakpoint-xl) {
          margin-bottom: 32px;
        }

        @media (min-width: $breakpoint-xxl) {
          margin-bottom: 40px;
        }
      }
    }
  }
  
  .test-start-img-wrapper {
    &.mobile {
      margin-bottom: 24px;

      @media (min-width: $breakpoint-md-2) {
        display: none;
      }
    }
    
    &.desktop {
      display: none;

      @media (min-width: $breakpoint-md-2) {
        display: block;
        width: 303px;
      }
      
      @media (min-width: $breakpoint-xl) {
        width: 413px;
      }
      
      @media (min-width: $breakpoint-xxl) {
        width: 523px;
      }
    }
    
    img {
      width: 100%;
      height: auto;
    }
  }
  
  .test-start-subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;

    @media (min-width: $breakpoint-md-2) {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 32px;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 48px;
    }

    @media (min-width: $breakpoint-xxl) {
      margin-bottom: 64px;
    }
  }
}

.test-info-section {
  margin-bottom: 64px;

  @media (min-width: $breakpoint-md-2) {
    display: flex;
    gap: 45px;
    margin-bottom: 100px;
  }
  
  @media (min-width: $breakpoint-xl) {
    gap: 56px;
    margin-bottom: 120px;
  }  
  
  @media (min-width: $breakpoint-xxl) {
    align-items: center;
    gap: 78px;
    margin-bottom: 160px;
  }

  .test-info-img-wrapper {
    position: relative;
    padding: 20px 26px 30px 13px;

    &.mobile {
      margin-bottom: 24px;

      @media (min-width: $breakpoint-md-2) {
        display: none;
      }
    }

    &.desktop {
      display: none;

      @media (min-width: $breakpoint-md-2) {
        display: block;
        align-self: flex-start;
        padding: 24px 15px 41px 31px;
      }

      @media (min-width: $breakpoint-xl) {
        padding: 39px 25px 68px 48px;
      }

      @media (min-width: $breakpoint-xxl) {
        padding: 42px 27px 73px 37px;
      }
    }

    .test-info-img-decoration {
      position: absolute;
      background-size: contain;

      &.test-info-img-decoration-1 {
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='15' viewBox='0 0 21 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.14355 1.05827L10.505 8.74205L10.8222 9.00242L11.1395 8.74205L20.5 1.05908L20.5 6.21563L10.8214 14.1598L1.14355 6.21564L1.14355 1.05827Z' fill='%2300AEEF' fill-opacity='0.1' stroke='%2300AEEF'/%3E%3C/svg%3E%0A");
        width: 21px;
        height: 15px;
        top: 0;
        right: 0;

        @media (min-width: $breakpoint-xl) {
          width: 31px;
          height: 23px;
        }

        @media (min-width: $breakpoint-xxl) {
          width: 34px;
          height: 26px;
        }
      }

      &.test-info-img-decoration-2 {
        background-image: url("data:image/svg+xml,%3Csvg width='27' height='20' viewBox='0 0 27 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-0.00097936 11.2837L-0.000978574 19.998L13.4996 8.7124L26.999 19.9969L26.999 11.2837L13.4986 -0.00195198L-0.00097936 11.2837Z' fill='%23E60036' fill-opacity='0.1'/%3E%3Cpath d='M0.499021 18.9284L13.179 8.32878L13.4996 8.06072L13.8203 8.32878L26.499 18.9273L26.499 11.5174L13.4986 0.649748L0.499021 11.5174L0.499021 18.9284Z' stroke='%23ED1847' stroke-opacity='0.2'/%3E%3C/svg%3E%0A");
        width: 27px;
        height: 20px;
        bottom: 0;
        left: 0;

        @media (min-width: $breakpoint-md-2) {
          width: 41px;
          height: 31px;
        }

        @media (min-width: $breakpoint-xl) {
          width: 67px;
          height: 51px;
        }

        @media (min-width: $breakpoint-xxl) {
          width: 73px;
          height: 55px;
        }
      }
    }

    .test-info-img-holder {
      position: relative;

      @media (min-width: $breakpoint-md-2) {
        width: 295px;
      }

      @media (min-width: $breakpoint-xl) {
        width: 478px;
      }

      @media (min-width: $breakpoint-xxl) {
        width: 519px;
      }

      &:before {
        content: '';
        position: absolute;
        border-radius: 16px;
        background: $blue-6;
        width: 100%;
        height: 100%;
        rotate: 4deg;
        left: 5px;
        bottom: 0;
      }

      img {
        position: relative;
        border-radius: 16px;
        width: 100%;
        height: auto;
      } 
    }
  }
}

.test-instruction-title {
  font-size: 24px;
  line-height: 30.17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: $breakpoint-md-2) {
    text-align: left;
    font-size: 28px;
    line-height: 36.4px;
    margin-bottom: 24px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 48px;
  }

  @media (min-width: $breakpoint-xxl) {
    font-size: 48px;
    line-height: 62.4px;
  }
}

.test-instruction-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  @media (min-width: $breakpoint-md-2) {
    flex-direction: row;
    gap: 24px;
  }

  @media (min-width: $breakpoint-xl) {
    gap: 32px;
  }
  
  .test-instruction-item {
    padding: 16px 16px 24px;
    border: 1px solid $grey-1;
    border-radius: 16px;

    @media (min-width: $breakpoint-md-2) {
      padding: 12px 16px 18px;
    }

    @media (min-width: $breakpoint-xl) {
      padding: 32px 32px 48px;
    }
    
    .test-instruction-head {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 25.14px;

      @media (min-width: $breakpoint-md-2) {
        height: 60px;
        font-size: 20px;
        line-height: 30px;
        gap: 10px;
      }

      @media (min-width: $breakpoint-xl) {
        height: 72px;
        font-size: 24px;
        line-height: 36px;
        gap: 24px;
        margin-bottom: 14px;
      }
      
      .test-instruction-index {
        font-size: 24px;
        line-height: 31.2px;
        font-weight: 600;
        position: relative;
        color: $blue-1;
        margin-top: -7px;

        @media (min-width: $breakpoint-xl) {
          font-size: 32px;
          line-height: 41.6px;
        }
        
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 2px;
          background: $blue-1;
        }
      }
    }
    
    .test-instruction-image {
      display: block;
      width: 220px;
      height: auto;
      margin: 0 auto 12px;

      @media (min-width: $breakpoint-md-2) {
        width: 200px;
        margin-bottom: 24px;
      }

      @media (min-width: $breakpoint-xxl) {
        width: 220px;
      }
    }
    
    .test-instruction-description {
      font-size: 16px;
      line-height: 24px;

      @media (min-width: $breakpoint-md-2) {
        font-size: 14px;
        line-height: 17.6px;
      }

      @media (min-width: $breakpoint-xl) {
        font-size: 18px;
        line-height: 22.63px;
      }
    }
  }
}

.test-instruction-prompt {
  border-radius: 16px;
  border: 1px solid $grey-1;
  padding: 16px 24px;
  margin-bottom: 64px;

  @media (min-width: $breakpoint-md-2) {
    display: flex;
    gap: 24px;
    align-items: center;
    padding: 16px;
    margin-bottom: 100px;
  }

  @media (min-width: $breakpoint-xl) {
    gap: 64px;
    padding: 32px;
    margin-bottom: 120px;
  }
  
  @media (min-width: $breakpoint-xxl) {
    padding: 32px 64px;
    margin-bottom: 160px;
  }

  .test-instruction-prompt-image-wrapper {
    text-align: center;

    &.mobile {
      margin-bottom: 20px;

      @media (min-width: $breakpoint-md-2) {
        display: none;
      }
    }

    &.desktop {
      display: none;

      @media (min-width: $breakpoint-md-2) {
        display: block;
      }
    }

    img {
      width: 250px;
      height: auto;

      @media (min-width: $breakpoint-md-2) {
        width: 347px;
      }

      @media (min-width: $breakpoint-xl) {
        width: 535px;
      }
    }
  }
  
  .test-instruction-prompt-holder {
    
  }
  
  .test-instruction-prompt-title {
    font-size: 20px;
    line-height: 25.14px;
    margin-bottom: 20px;

    @media (min-width: $breakpoint-md-2) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 16px;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 20px;
    }

    @media (min-width: $breakpoint-xxl) {
      margin-bottom: 24px;
    }
  }
  
  .test-instruction-prompt-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;

    @media (min-width: $breakpoint-md-2) {
      font-size: 14px;
      line-height: 17.6px;
      margin-bottom: 24px;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 18px;
      line-height: 22.63px;
      margin-bottom: 32px;
    }

    @media (min-width: $breakpoint-xxl) {
      margin-bottom: 48px;
    }
  }
}

.test-wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 0 64px;

  @media (min-width: $breakpoint-md-2) {
    padding: 140px 155px;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 114px 105px;
  }

  @media (min-width: $breakpoint-xxl) {
    padding: 114px 265px;
  }
}

.test-progress {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 32px;

  @media (min-width: $breakpoint-xl) {
    gap: 32px;
    margin-bottom: 80px;
  }

  .test-progress-wrapper {
    position: relative;
    flex: 1;
    height: 4px;
    background-color: $blue-5;
    border-radius: 1000px;

    @media (min-width: $breakpoint-xl) {
      height: 8px;
    }

    .test-progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: $blue-1;
      border-radius: 1000px;
    }
  }
}

.test-items {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin-bottom: 24px;

  @media (min-width: $breakpoint-md-2) {
    grid-template-columns: 1fr 1fr;
    gap: 16px 24px;
  }
  
  @media (min-width: $breakpoint-xl) {
    gap: 12px 32px;
    margin-bottom: 32px;
  }
  
  .test-item {
    display: flex;
    align-items: center;
    min-height: 52px;
    gap: 12px;
    padding: 0 16px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    border: 1px solid $grey-1;
    border-radius: 8px;
    z-index: 2;
    transition: all 200ms ease-in;
    cursor: pointer;

    @media (min-width: $breakpoint-xl) {
      min-height: 55px;
      font-size: 18px;
      line-height: 22.63px;
    }
    
    &.selected {
      border: 1px solid $blue-1;
    }
    
    .test-item-label {
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.test-question {
  text-align: center;
  margin-bottom: 16px;

  @media (min-width: $breakpoint-md-2) {
    text-align: left;
  }

  @media (min-width: $breakpoint-xl) {
    margin-bottom: 20px;
  }

  @media (min-width: $breakpoint-xxl) {
    margin-bottom: 28px;
  }

  .test-question-title {
    font-size: 24px;
    line-height: 30.17px;
    font-weight: 400;
    margin-bottom: 4px;

    @media (min-width: $breakpoint-xl) {
      margin-bottom: 10px;
    }
  }

  .test-question-text {
    font-size: 14px;
    line-height: 17.6px;
    color: $grey-4;
  }
}

.test-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 32px;

  @media (min-width: $breakpoint-md-2) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    
    .test-previous-btn {
      margin: 0 63px 0 auto;
    }
  }
  
  @media (min-width: $breakpoint-md-2) {
    gap: 32px;
    
    .test-previous-btn {
      margin-right: 48px;
    }
  }
}

.test-previous-btn {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
  font-size: 16px;
  line-height: 20.11px;
  font-weight: 400;
  text-transform: uppercase;
  color: $grey-4;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &::before {
    content: "";
    display: flex;
    width: 17px;
    height: 8px;
    background: url("data:image/svg+xml,%3Csvg width='17' height='8' viewBox='0 0 17 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.93405 7.1115L3.83371 7.20179L3.73338 7.1115L0.399662 4.1115L0.275314 3.9996L0.400061 3.88814L3.75777 0.888143L3.86035 0.796495L3.96052 0.890769L4.48395 1.3834L4.60261 1.49508L4.48151 1.60411L2.36736 3.50752H16.5H16.65V3.65752V4.34248V4.49248H16.5H2.36736L4.48151 6.39589L4.60535 6.5074L4.48148 6.61887L3.93405 7.1115Z' fill='%23C7CCD3' stroke='%23C7CCD3' stroke-width='0.3'/%3E%3C/svg%3E%0A") no-repeat;
  }
  
  &:disabled {
    cursor: default;
  }
}

.loader {
  width: 18px;
  height: 18px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-outside: 4px;
  margin-left: 10px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.test-result-header-inner {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 64px;

  @media (min-width: $breakpoint-md-2) {
    flex-direction: row;
    gap: 64px;
    margin-bottom: 100px;
  }

  @media (min-width: $breakpoint-xl) {
    gap: 103px;
    margin-bottom: 120px;
  }

  @media (min-width: $breakpoint-xxl) {
    align-items: center;
    gap: 297px;
    margin-bottom: 160px;
  }
}

.test-result-header-img {
  display: block;
  width: 100%;
  height: auto;

  @media (min-width: $breakpoint-md-2) {
    margin-left: -40px;
    width: 403px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-left: 0;
    width: 423px;
  }

  @media (min-width: $breakpoint-xxl) {
    width: 499px;
  }
}

.test-result-header-info {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px 12px;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  margin-bottom: 64px;

  @media (min-width: $breakpoint-md-2) {
    flex: 1;
    margin-bottom: 0;
    gap: 8px 16px;
  }

  .test-result-info-title {
    text-align: right;
    white-space: nowrap;
  }

  .test-result-bar-wrapper {
    height: 32px;
    border: 2px solid $blue-5;
    background: $blue-5;
    border-radius: 1000px;

    .test-result-bar {
      position: relative;
      height: 100%;
      background: $blue-1;
      border-radius: 1000px;
      text-align: center;
      min-width: 35px;

      .test-result-bar-value {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        line-height: 17.6px;
        color: $white;

        @media (min-width: $breakpoint-md-2) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

.test-info-title {
  font-size: 24px;
  line-height: 30.17px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 24px;

  @media (min-width: $breakpoint-md-2) {
    font-size: 28px;
    line-height: 36.4px;
    text-align: left;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 50px;
  }

  @media (min-width: $breakpoint-xxl) {
    font-size: 48px;
    line-height: 62.4px;
    margin-bottom: 40px;
  }
}

.test-info-detail {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: $breakpoint-md-2) {
    flex: 1;
  }

  @media (min-width: $breakpoint-xl) {
    gap: 16px;
  }
}

.test-result-info {
  margin-bottom: 64px;

  @media (min-width: $breakpoint-md-2) {
    display: flex;
    gap: 24px;
    align-items: flex-start;
    margin-bottom: 100px;
  }
  
  @media (min-width: $breakpoint-xl) {
    gap: 53px;
    margin-bottom: 120px;
  }
  
  @media (min-width: $breakpoint-xxl) {
    gap: 111px;
    margin-bottom: 160px;
  }

  .test-result-info-img {
    width: 100%;
    height: auto;
    margin-bottom: 24px;

    @media (min-width: $breakpoint-md-2) {
      width: 407px;
      margin-left: -44px;
      margin-bottom: 0;
    }

    @media (min-width: $breakpoint-xl) {
      width: 528px;
      margin-top: 56px;
      margin-left: -54px;
    }

    @media (min-width: $breakpoint-xxl) {
      width: 605px;
      margin-left: -53px;
    }
  }
}

.test-personality {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  @media (min-width: $breakpoint-md-2) {
    flex-direction: row;
    align-items: flex-start;
  }

  @media (min-width: $breakpoint-xl) {
    gap: 114px;
  }

  @media (min-width: $breakpoint-xxl) {
    gap: 268px;
  }
  
  .test-personality-img {
    width: 100%;
    height: auto;

    @media (min-width: $breakpoint-md-2) {
      width: 363px;
    }

    @media (min-width: $breakpoint-xl) {
      width: 412px;
    }

    @media (min-width: $breakpoint-xxl) {
      width: 394px;
    }
  }

  .test-personality-text {
    --color-1: #{$blue-1};
    --color-2: #{$blue-7};
    --color-3: #{$blue-8};

    font-size: 16px;
    line-height: 24px;

    @media (min-width: $breakpoint-xl) {
      font-size: 18px;
      line-height: 22.63px;
    }
    
    .test-personality-description {
      margin-bottom: 24px;

      @media (min-width: $breakpoint-md-2) {
        margin-bottom: 16px;
      }

      @media (min-width: $breakpoint-xl) {
        margin-bottom: 24px;
      }
    }

    .test-personality-charts {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 24px;

      @media (min-width: $breakpoint-md-2) {
        flex-direction: row;
        gap: 24px;
        margin-bottom: 16px;
      }

      @media (min-width: $breakpoint-xl) {
        margin-bottom: 24px;
      }

      @media (min-width: $breakpoint-xxl) {
        gap: 36px;
      }

      .test-personality-chart-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media (min-width: $breakpoint-md-2) {
          flex: 1;
        }

        @media (min-width: $breakpoint-xl) {
          gap: 12px;
        }

        .test-personality-chart {
          display: flex;
          height: 32px;
          background: $blue-5;
          border: 4px solid $blue-5;
          border-radius: 1000px;

          @media (min-width: $breakpoint-xl) {
            height: 40px;
          }

          .test-personality-bar {
            height: 100%;
            border-radius: 1000px;
          }

          &:nth-child(1) .test-personality-bar {
            background: var(--color-1);
          }

          &:nth-child(2) .test-personality-bar {
            background: var(--color-2);
          }

          &:nth-child(3) .test-personality-bar {
            background: var(--color-3);
          }
        }
      }
    }

    .test-personality-label-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 16px;
      border: 1px solid $base;
      border-radius: 16px;

      @media (min-width: $breakpoint-md-2) {
        order: -1;
        gap: 6px;
      }

      @media (min-width: $breakpoint-xl) {
        font-size: 18px;
        line-height: 22.63px;
        padding: 20px;
        gap: 17.5px;
      }

      .test-personality-label {
        position: relative;
        padding-left: 20px;
        font-size: 18px;
        line-height: 22.63px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }

        &:nth-child(1)::before {
          background: var(--color-1);
        }

        &:nth-child(2)::before {
          background: var(--color-2);
        }

        &:nth-child(3)::before {
          background: var(--color-3);
        }
      }
    }
  }
}

.test-personality-descriptions {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: $breakpoint-md-2) {
    gap: 4px;
  }
}

.test-info-text {
  .test-info-text-sections {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: $breakpoint-md-2) {
      gap: 20px;
    }

    @media (min-width: $breakpoint-xl) {
      gap: 32px;
    }

    @media (min-width: $breakpoint-xxl) {
      gap: 40px;
    }
  }

  .test-info-text-title {
    font-size: 24px;
    line-height: 30.17px;
    font-weight: 400;
    margin-bottom: 24px;
    text-align: center;

    @media (min-width: $breakpoint-md-2) {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 12px;
      text-align: left;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 20px;
    }

    @media (min-width: $breakpoint-xxl) {
      font-size: 32px;
      line-height: 40.22px;
    }
  }
  
  .test-info-text-description {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media (min-width: $breakpoint-md-2) {
      font-size: 14px;
      line-height: 17.6px;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (min-width: $breakpoint-xxl) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .test-info-text-subtitle {
    font-size: 20px;
    line-height: 25.14px;
    font-weight: 400;
    margin-bottom: 12px;

    @media (min-width: $breakpoint-xl) {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 20px;
    }

    @media (min-width: $breakpoint-xxl) {
      font-size: 32px;
      line-height: 40.22px;
    }
  }
}

