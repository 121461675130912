.title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 32px;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: $breakpoint-md-2) {
    font-size: 40px;
    line-height: 50.28px;
    margin-bottom: 24px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 64px;
    line-height: 80.45px;
    margin-bottom: 40px;
  }

  @media (min-width: $breakpoint-xxl) {
    font-size: 80px;
    line-height: 100.56px;
    margin-bottom: 64px;
  }

  &.with-subtitle {
    margin-bottom: 12px;

    @media (min-width: $breakpoint-md-2) {
      margin-bottom: 16px;
    }
  }
}

.page-subtitle {
  font-size: 24px;
  line-height: 30.17px;
  font-weight: 400;
  margin-bottom: 24px;
  text-align: center;

  @media (min-width: $breakpoint-md-2) {
    text-align: left;
    font-size: 28px;
    line-height: 36.4px;
  }
  
  @media (min-width: $breakpoint-xl) {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 40px;
  } 

  @media (min-width: $breakpoint-xxl) {
    font-size: 48px;
    line-height: 62.4px;
    margin-bottom: 40px;
  }
}

.page-caption {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 40px;

  @media (min-width: $breakpoint-md-2) {
    font-size: 18px;
    line-height: 22.63px;
    margin-bottom: 32px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 48px;
  }
  
  @media (min-width: $breakpoint-xxl) {
    margin-bottom: 60px;
  }
}
