.test-result-info{

  .card-result {
    padding: 16px;
    border: 1px solid $grey-1;
    border-radius: 16px;

    @media (min-width: $breakpoint-xl) {
      padding: 24px;
    }
  }
  
  .card-result-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;

    @media (min-width: $breakpoint-md-2) {
      flex-direction: row;
      align-items: center;
    }
  }
  
  .card-result-title {
    font-size: 16px;
    line-height: 20.8px;

    @media (min-width: $breakpoint-md-2) {
      font-size: 18px;
      line-height: 23.4px;
      flex: 1;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 20px;
      line-height: 25.14px;
    }

    @media (min-width: $breakpoint-xxl) {
      font-size: 24px;
      line-height: 30.17px;
    }

    .card-result-title-index {
      font-weight: 600;
    }
  }
  
  .card-result-chart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @media (min-width: $breakpoint-md-2) {
      gap: 4px;
    }
    
    .card-result-chart-label {
      font-size: 14px;
      line-height: 17.6px;

      @media (min-width: $breakpoint-md-2) {
        font-size: 16px;
        line-height: 19.2px;
      }
    }

    .card-result-chart-bar {
      --right-margin: 0;
      
      position: relative;
      display: flex;
      width: 155px;
      height: 24px;
      border-radius: 1000px;
      background: $blue-5;
      margin-right: var(--right-margin);

      @media (min-width: $breakpoint-md-2) {
        border: 2px solid $blue-5;
        height: 12px;
        order: -1;
        --right-margin: 47px;
      }

      @media (min-width: $breakpoint-xl) {
        width: 190px;
        --right-margin: 47px;
      }

      .card-result-bar {
        position: relative;
        border-radius: 1000px;
        background: $blue-1;
        min-width: 30px;

        @media (min-width: $breakpoint-md-2) {
          position: static;
        }

        .card-result-bar-value {
          font-size: 14px;
          line-height: 17.6px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $white;

          @media (min-width: $breakpoint-md-2) {
            font-size: 16px;
            line-height: 20.11px;
            font-weight: 700;
            color: $base;
            left: auto;
            transform: translateY(-50%);
            right: calc(var(--right-margin) * -1);
          }
        }
      }
    }
  }
  
  .items-list {
    margin-bottom: 12px;
  }
  
  .card-result-button {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
    color: $base;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    width: 100%;
  
    &::before {
      content: "";
      order: 2;
      flex: 1;
      border-bottom: 1px solid $base;

      @media (min-width: $breakpoint-md-2) {
        width: 165px;
        flex: unset;
      }
    }

    &::after {
      content: "";
      order: 3;
      width: 32px;
      height: 32px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='20 12 32 32'%3E%3Cg filter='url(%23filter0_d_1381_13927)'%3E%3Cellipse cx='36' cy='28' rx='16' ry='16' transform='rotate(180 36 28)' fill='%2300AEEF'/%3E%3C/g%3E%3Cpath d='M35.9214 22L32 22L37.0786 28L32 34L35.9214 34L41 28L35.9214 22Z' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_1381_13927' x='0' y='0' width='72' height='72' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='8'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.670833 0 0 0 0 0.670833 0 0 0 0 0.670833 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1381_13927'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1381_13927' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E") no-repeat;
      background-size: contain;
    }
  }
}
