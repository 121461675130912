.degree-controls {
  position: relative;
  width: 100%;
}

#propositions-table-wrapper {
  order: 1;
  flex: 1 1 auto;
  display: none;

  @media (min-width: $breakpoint-xl) {
    display: block;
  }
}

.propositions-wrapper {
  margin-bottom: 64px;

  @media (min-width: $breakpoint-xxl) {
    margin-bottom: 160px;
  }
  
  .title {
    margin-bottom: 56px;
  }
}

.specialities-control-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
