.search-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.24444 14.4828C8.85331 14.4828 10.4162 13.946 11.6854 12.9574L16.474 17.746C16.8317 18.0915 17.4018 18.0815 17.7472 17.7238C18.0843 17.3749 18.0843 16.8217 17.7472 16.4728L12.9587 11.6842C15.4118 8.52626 14.8406 3.97759 11.6827 1.5244C8.52476 -0.928781 3.97612 -0.35752 1.52294 2.80039C-0.930246 5.9583 -0.358985 10.507 2.79892 12.9602C4.07027 13.9478 5.63452 14.4836 7.24444 14.4828ZM3.39686 3.39556C5.52185 1.27054 8.96712 1.2705 11.0921 3.39548C13.2172 5.52046 13.2172 8.96574 11.0922 11.0908C8.96724 13.2158 5.52196 13.2158 3.39694 11.0908C3.3969 11.0908 3.3969 11.0908 3.39686 11.0908C1.27188 8.98126 1.25936 5.54854 3.36886 3.42356C3.37818 3.4142 3.3875 3.40488 3.39686 3.39556Z' fill='%23282828'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.close-icon{
  display: inline-block;
  width: 10px;
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.38477 1.38477L10.6155 10.6155' stroke='%23282828' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M1.38477 10.6157L10.6155 1.38495' stroke='%23282828' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.star-icon{
  display: block;
  width: 19px;
  height: 24px;
  top: 0;
  right: 0;
  background-color: $grey-4;
  mask-image: url("data:image/svg+xml,%3Csvg width='20' height='24' viewBox='0 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.0062 0H2.15625C0.967312 0 0 0.967312 0 2.15625V23.25C0 23.5388 0.165797 23.8019 0.426375 23.9266C0.686906 24.0511 0.995766 24.0152 1.22067 23.8339L9.58125 17.0951L17.9418 23.8339C18.0778 23.9435 18.2445 24 18.4126 24C18.5226 24 18.6331 23.9758 18.7361 23.9266C18.9967 23.802 19.1625 23.5388 19.1625 23.25V2.15625C19.1625 0.967312 18.1952 0 17.0062 0ZM17.6625 21.6822L10.0519 15.5479C9.91453 15.4372 9.74794 15.3818 9.58125 15.3818C9.41456 15.3818 9.24797 15.4372 9.11058 15.5479L1.5 21.6822V2.15625C1.5 1.79437 1.79437 1.5 2.15625 1.5H17.0062C17.3681 1.5 17.6625 1.79437 17.6625 2.15625V21.6822Z' fill='%23A0A6AF'/%3E%3C/svg%3E%0A");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;

  &.is-active {
    background-color: $blue-1;
    mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 1H3V23L11.5 16L20 23V1Z' fill='%2300AEEF'/%3E%3Cpath d='M19.0062 0H4.15625C2.96731 0 2 0.967312 2 2.15625V23.25C2 23.5388 2.1658 23.8019 2.42638 23.9266C2.68691 24.0511 2.99577 24.0152 3.22067 23.8339L11.5812 17.0951L19.9418 23.8339C20.0778 23.9435 20.2445 24 20.4126 24C20.5226 24 20.6331 23.9758 20.7361 23.9266C20.9967 23.802 21.1625 23.5388 21.1625 23.25V2.15625C21.1625 0.967312 20.1952 0 19.0062 0ZM19.6625 21.6822L12.0519 15.5479C11.9145 15.4372 11.7479 15.3818 11.5812 15.3818C11.4146 15.3818 11.248 15.4372 11.1106 15.5479L3.5 21.6822V2.15625C3.5 1.79437 3.79437 1.5 4.15625 1.5H19.0062C19.3681 1.5 19.6625 1.79437 19.6625 2.15625V21.6822Z' fill='%2300AEEF'/%3E%3C/svg%3E%0A");
  }
}

@mixin sort-icon {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: $grey-4;
  mask-image: url("data:image/svg+xml,%3Csvg width='6' height='3' viewBox='0 0 6 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.40241 3L0.804337 -4.54262e-07L6.00049 0L3.40241 3Z' fill='%239E9E9E'/%3E%3C/svg%3E%0A");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;

  &.is-active {
    background-color: $blue-1;
  }

  .degree-controls &{
    mask-image: url("data:image/svg+xml,%3Csvg width='6' height='4' viewBox='0 0 6 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.39802 3.84399L0.799942 0.843994L5.99609 0.843994L3.39802 3.84399Z' fill='%23A0A6AF'/%3E%3C/svg%3E%0A");
  }
}

.sort-up-icon {
  @include sort-icon;

  transform: rotate(180deg);
}

.sort-down-icon {
  @include sort-icon;
}

.question-icon{
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: $base;
  mask-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 13.1094C8.43147 13.1094 8.78125 12.7596 8.78125 12.3281C8.78125 11.8967 8.43147 11.5469 8 11.5469C7.56853 11.5469 7.21875 11.8967 7.21875 12.3281C7.21875 12.7596 7.56853 13.1094 8 13.1094Z' fill='%23282828'/%3E%3Cpath d='M8 0.5C3.57863 0.5 0 4.07803 0 8.5C0 12.9214 3.57803 16.5 8 16.5C12.4214 16.5 16 12.922 16 8.5C16 4.07863 12.422 0.5 8 0.5ZM8 15.25C4.26947 15.25 1.25 12.231 1.25 8.5C1.25 4.76947 4.26897 1.75 8 1.75C11.7305 1.75 14.75 4.76897 14.75 8.5C14.75 12.2305 11.731 15.25 8 15.25Z' fill='%23282828'/%3E%3Cpath d='M8 4.51562C6.6215 4.51562 5.5 5.63713 5.5 7.01562C5.5 7.36081 5.77981 7.64062 6.125 7.64062C6.47019 7.64062 6.75 7.36081 6.75 7.01562C6.75 6.32638 7.31075 5.76562 8 5.76562C8.68925 5.76562 9.25 6.32638 9.25 7.01562C9.25 7.70487 8.68925 8.26562 8 8.26562C7.65481 8.26562 7.375 8.54544 7.375 8.89062V10.4531C7.375 10.7983 7.65481 11.0781 8 11.0781C8.34519 11.0781 8.625 10.7983 8.625 10.4531V9.43653C9.70206 9.15831 10.5 8.17841 10.5 7.01562C10.5 5.63713 9.3785 4.51562 8 4.51562Z' fill='%23282828'/%3E%3C/svg%3E ");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}
