.title-arrow {
  margin: 0 auto 20px;
  mask-image: url("data:image/svg+xml,%3Csvg width='41' height='30' viewBox='0 0 41 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.259996 13.0715L0.259997 -1.58956e-06L20.2481 16.9285L40.2344 0.00165367L40.2344 13.0715L20.2465 30L0.259996 13.0715Z' fill='%23ED1847'/%3E%3C/svg%3E%0A");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background: $red-1;
  background-size: cover;
  width: 32px;
  height: 24px;
  
  &.secondary {
    background: $blue-1;
  }

  @media (min-width: $breakpoint-md-2) {
    width: 32px;
    height: 24px;
    margin-bottom: 16px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-bottom: 32px;
  }

  @media (min-width: $breakpoint-xxl) {
    width: 40px;
    height: 30px;
    margin-bottom: 48px;
  }
}

.title-arrow-2 {
  background: url("data:image/svg+xml,%3Csvg width='33' height='24' viewBox='0 0 33 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.499994 10.4572L0.499994 -2.79753e-06L16.5007 13.5428L32.5 0.00131989L32.5 10.4572L16.4994 24L0.499994 10.4572Z' fill='%2300AEEF'/%3E%3C/svg%3E%0A") no-repeat;
  background-size: contain;
  width: 32px;
  height: 24px;
  margin: 0 auto 16px;

  @media (min-width: $breakpoint-md-2) {
    width: 36px;
    height: 30px;
    margin: 0 0 20px;
  }

  @media (min-width: $breakpoint-xl) {
    width: 40px;
    height: 30px;
  }
  
  @media (min-width: $breakpoint-xxl) {
    margin-bottom: 32px;
  }
}