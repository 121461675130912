.checkbox-label {
  display: inline-flex;
}

.checkbox-input {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 0;
  opacity: 0;
  display: none;
  visibility: hidden;
  left: -9999px;

  &:checked + .checkbox-box, &.checked + .checkbox-box {
    background-color: $blue-1;
    background-image: url(/assets/img/check-mark-1.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    border-color: $blue-1;
  }
}

.checkbox-box {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid $grey-4;
  border-radius: 4px;
  cursor: pointer;

  &.in-search {
    z-index: 9999 !important;
    cursor: pointer;
  }
}

.checkbox-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 4px;
  
  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: $blue-light;
    border-radius: 4px;
  }
}
