.error + .footer {
  display: none;
}

.footer {
  position: relative;
  z-index: 1000;
  display: block;
  padding: 32px var(--main-space);
  margin: 0 calc(var(--main-space) * -1);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: $white-light;
  background-color: $blue-3;

  @media (min-width: $breakpoint-md-2) {
    font-size: 16px;
    line-height: 24px;
    padding-top: 48px;
    padding-bottom: 24px;
  }
  
  @media (min-width: $breakpoint-xl) {
    font-size: 18px;
    line-height: 22.63px;
    padding-top: 64px;
    padding-bottom: 32px;
  }  
  
  @media (min-width: $breakpoint-xxl) {
    padding-top: 95px;
    padding-bottom: 32px;
  }
}

.footer-wrapper {
  margin-bottom: 36px;

  @media (min-width: $breakpoint-md-2) {
    display: flex;
    margin-bottom: 24px;
    flex-wrap: wrap;
  }
  
  @media (min-width: $breakpoint-xl) {
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 80px;
  }
}

.footer-logo-holder {
  display: flex;
  margin-bottom: 24px;

  @media (min-width: $breakpoint-md-2) {
    margin-bottom: 12px;
  }
  
  @media (min-width: $breakpoint-xl) {
    margin-bottom: 32px;
  }
  
  img {
    width: 117px;
    height: auto;

    @media (min-width: $breakpoint-md-2) {
      width: 117px;
    }
  }
}

.footer-text {
  margin-bottom: 16px;

  @media (min-width: $breakpoint-md-2) {
    margin-bottom: 20px;
    letter-spacing: -1px;
  }
  
  @media (min-width: $breakpoint-xl) {
    margin-bottom: 24px;
    letter-spacing: 0;
  }
  
  @media (min-width: $breakpoint-xxl) {
    width: 499px;
  }
}

.footer-info {
  margin-bottom: 36px;

  @media (min-width: $breakpoint-md-2) {
    margin-bottom: 0;
    flex: 1;
  }
}

.footer-support-link {
  color: $blue-1;
  
  &:hover {
    text-decoration: underline;
  }
}

.footer-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 36px;

  @media (min-width: $breakpoint-md-2) {
    gap: 16px;
    margin-bottom: 0;
  }

  @media (min-width: $breakpoint-xl) {
    gap: 24px;
  }
}

.footer-link {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.footer-contacts {

  @media (min-width: $breakpoint-md-2) {
    display: flex;
    margin-left: 20px;
  }
  
  @media (min-width: $breakpoint-xl) {
    margin-left: 28px;
  }  

  @media (min-width: $breakpoint-xxl) {
    margin-left: 164px;
  }
}

.footer-contacts-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 36px;

  @media (min-width: $breakpoint-md-2) {
    margin-left: 20px;
    margin-bottom: 0;
  }

  @media (min-width: $breakpoint-xl) {
    margin-left: 105px;
    gap: 24px;
  }

  @media (min-width: $breakpoint-xxl) {
    margin-left: 161px;
  }
}

.footer-contacts-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 4px;
  
  @media (min-width: $breakpoint-md-2) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
  }
}

.footer-contacts-item {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 18px;
  line-height: 22.63px;

  @media (min-width: $breakpoint-md-2) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 18px;
    line-height: 24px;
  }
}

.footer-social-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 20px;

  @media (min-width: $breakpoint-md-2) {
    margin-bottom: 16px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-bottom: 24px;
  }

  .degree-info-contacts & {
    margin-top: 14px;
  }
}

.footer-social {
  @media (min-width: $breakpoint-md-2) {
    margin-left: 20px;
  }
  
  @media (min-width: $breakpoint-xl) {
    margin-left: 43px;
  }  
  
  @media (min-width: $breakpoint-xxl) {
    margin-left: 138px;
  }
}

.social-wrapper {
  display: flex;
  gap: 24px;

  @media (min-width: $breakpoint-md-2) {
    gap: 22px;
  }

  @media (min-width: $breakpoint-xl) {
    gap: 24px;
  }
}

.social-link {
  --size: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: $grey-2;

  @media (min-width: $breakpoint-md-2) {
    --size: 40px;
  }
  
  @media (min-width: $breakpoint-xl) {
    --size: 48px;
  }
  
  img {
    width: 100%;
    height: 100%;
  }
}

.footer-rights {
  font-size: 18px;
  line-height: 22.63px;
  text-align: center;
  color: $grey-4;
}

.footer-menu-link {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $white;

  @media (min-width: $breakpoint-md-2) {
    
  }
}
