.calculator-description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 14px;
  line-height: 17.6px;

  @media (min-width: $breakpoint-md-2) {
    padding: 0 77px;
    font-size: 16px;
    line-height: 24px;
    gap: 32px;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 0 105px;
    font-size: 18px;
    line-height: 22.63px;
  }
  
  .calculator-description-title {
    font-size: 18px;
    line-height: 22.63px;
    font-weight: 400;
    margin-bottom: 12px;

    @media (min-width: $breakpoint-md-2) {
      font-size: 20px;
      line-height: 30px;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 24px;
      line-height: 36px;
    }
  }
  
  .calculator-description-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .items-list li {
    font-size: inherit;
    line-height: inherit;
  }
}