.button-scroll {
  position: fixed;
  bottom: 34px;
  right: 55px;
  width: 64px;
  height: 64px;
  background-color: #fff;
  filter: drop-shadow(0px 30px 16px rgba(0, 0, 0, 0.05));
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1001;
}

.button-scroll-arrow {
  width: 16px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.68714e-07 6.77143L1.71671e-06 12L8 5.22857L16 12L16 6.77143L8 1.14447e-06L9.68714e-07 6.77143Z' fill='%2300A6EB'/%3E%3C/svg%3E%0A");
}
