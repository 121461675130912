/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 10, 2024 */



@font-face {
    font-family: 'source_sans_proregular';
    src: url('sourcesanspro-regular-webfont.eot');
    src: url('sourcesanspro-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('sourcesanspro-regular-webfont.woff2') format('woff2'),
         url('sourcesanspro-regular-webfont.woff') format('woff'),
         url('sourcesanspro-regular-webfont.svg#source_sans_proregular') format('svg');
    font-weight: normal;
    font-style: normal;

}