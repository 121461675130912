$white-light: #fdfdfd;
$white: #fff;

$main-bg-color: #fff;
$header-bg: #ffc90c;
$base: #282828;

$blue-1: #00AEEF;
$blue-2: #E5F6FD;
$blue-3: #0C1628;
$blue-4: #00A6EB;
$blue-5: #D9F3FF;
$blue-6: #00AEEF1A;
$blue-7: #0F80F1;
$blue-8: #87C1FC;

$grey-1: #C7CCD3;
$grey-2: #3D4553;
$grey-3: #EEEEEE;
$grey-4: #A0A6AF;
$grey-5: rgba(171, 171, 171, 0.1);
$grey: #eee;
$grey-border: #e8e8e8;
$grey-dark: #9e9e9e;
$grey-light: #fafafb;

$accent: #0f80f1;
$accent-hover: #0d73d8;

$red-1: #ED1847;
$red-2: rgba(237, 24, 71, 0.05);
$error: #e30000;
$error-light: #fbf0f0;

$shadow: #aed3f8;

$checkbox-border: #6fb3f7;

$blue-light: #e3f1ff;

$active-degree: #ffefb8;
$active-star: #ffc43b;

$disabled-blue: #86bef7;
$disabled-grey: #929292;
