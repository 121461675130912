.mobile-table-cards {
  padding-top: 24px;
  display: block;

  @media (min-width: $breakpoint-md-2) {
    padding-top: 16px;
  }
  
  @media (min-width: $breakpoint-xl) {
    display: none;
  }

  .mobile-table-cards-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 24px;

    @media (min-width: $breakpoint-md-2) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 16px;
      column-gap: 24px;
    }

    .table-card {
      padding: 16px;
      border: 1px solid $grey-1;
      border-radius: 16px;

      &.is-active {
        background: $blue-2;
        border-color: $blue-1;
      }
      
      .star-icon {
        width: 19.16px;
        height: 24px;

        @media (min-width: $breakpoint-md-2) {
          width: 19px;
          height: 24px;
        }
      }
      
      .table-card-degree {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 8px;
      }

      .table-card-degree-partner {
        display: flex;
        align-items: center;
        gap: 17px;
        
        img {
          width: 44px;
          height: 25px;
        }
      }

      .table-card-degree-number {
        font-size: 14px;
        line-height: 17.6px;
        font-weight: 400;
      }

      .table-card-degree-name {
        font-size: 16px;
        line-height: 19.2px;
        font-weight: 400;
        margin-bottom: 12px;
      }

      .table-card-university-name {
        font-size: 14px;
        line-height: 17.6px;
        font-weight: 400;
        margin-bottom: 12px;
      }

      .table-card-result {
        display: flex;
        gap: 32px;
      }

      .table-card-mark {
        display: flex;
        flex-direction: column;
      }

      .table-card-mark-text {
        font-size: 14px;
        line-height: 17.6px;
      }

      .table-card-mark-number {
        font-size: 16px;
        line-height: 20.11px;
        font-weight: 600;
      }
    }
  }
}