.search {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 52px;
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 16px;
  padding-right: 32px;
  background: #fff;
  border: 1px solid $grey-1;
  border-radius: 8px;
  cursor: pointer;

  @media (min-width: $breakpoint-xl) {
    height: 56px;
  }
  
  &:hover {
    border-color: $grey-4;
  }

  .isActive &, .isActive &:hover {
    border-color: $blue-1;
  }
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 15px 0 12px;
  border: 1px solid $grey-1;
  border-radius: 8px;

  @media (min-width: $breakpoint-xl) {
    height: 56px;
    padding-left: 16px;
  }

  &:hover {
    border-color: $grey-4;
  }
  
  &:focus-within {
    border-color: $blue-1;
  }
}

.search-input {
  flex: 1;
  height: 100%;
  margin-left: 11px !important;

  .MuiInputBase-input, .MuiInputLabel-root {
    font-size: 16px;
    line-height: 19.2px;
  }
  
  .MuiInputBase-root, .MuiInputBase-input {
    height: 100%;
    width: 100%;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  
  .MuiInputBase-input {
    padding: 0;
    border: none;
  }
  
  .MuiInputLabel-root {
    white-space: unset;
    color: $grey-4;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: translateY(-50%);
    top: 50%;

    &.Mui-focused {
      display: none;
    }
  }

  &.has-value .MuiInputLabel-root {
    display: none;
  }
}

input:-webkit-autofill, input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px $white-light inset;
}

.layout-tabs-header {
  @media (min-width: $breakpoint-md-2) {
    display: flex;
    gap: 24.5px;
  }

  @media (min-width: $breakpoint-xl) {
    gap: 32px;
    margin-bottom: 32px;
  }
  
  .filters-holder {
    position: relative;
    display: flex;
    gap: 8px;
    margin-top: 17px;

    @media (min-width: $breakpoint-md-2) {
      margin-top: 0;
      width: 517px;
    }

    @media (min-width: $breakpoint-xl) {
      width: 705px;
      gap: 32px;
    }

    @media (min-width: $breakpoint-xxl) {
      width: 897px;
      gap: 32px;
    }
  }
}

.search-form {
  flex: 1;
  
  .subject-wrapper {
    flex: 1;
    height: 100%;
    margin: 0 0 0 8px;
  }
}

.subject-wrapper {
  position: relative;
}
