.filters{
  --x-space: 8px;
  
  position: absolute;
  padding: 16px 10px;
  right: 0;
  top: 60px;
  background-color: $white;
  border: 1px solid $grey-1;
  border-radius: 8px;
  z-index: 2;

  @media (min-width: $breakpoint-md-2) {
    width: auto !important;
    min-width: 343px !important;
  }
}

.filters-close {
  position: absolute;
  top: 16px;
  right: 10px;
  cursor: pointer;
}

.filter-title{
  display: block;
  font-size: 14px;
  line-height: 18px;
  padding: 0 var(--x-space);
  margin-bottom: 8px;
  text-transform: uppercase;
  color: $blue-1;
}

.filter-option{
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding: 0 var(--x-space);
  margin-bottom: 8px;

  .filter-options &{
    margin-bottom: 0;
  }
}

.filter-line{
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background-color: $grey-1;
}

.filter-switch{
  display: flex;
  justify-content: space-between;
  padding: 0 var(--x-space);
  margin-bottom: 16px;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.filter-options{
  display: flex;
  align-items: center;
}
