#root {
  --main-space: 16px;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 var(--main-space);
  
  @media (min-width: $breakpoint-md-2) {
    --main-space: 60px;
  } 

  @media (min-width: $breakpoint-xl) {
    --main-space: 104px;
  }
  
  @media (min-width: $breakpoint-xxl) {
    --main-space: 180px;
  }
  
  .page-content {
    flex: 1;
  }
}

.container {
  max-width: 1620px;
  margin: 0 auto;

  .letter & {
    display: flex;
    height: 100%;
    padding: 0 30px;
  }

  .degree-info & {
    max-width: 1680px;
    padding: 0 60px;
  }
}
