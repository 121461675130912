.question-helper.not-hovered {
  display: none;
}

.question-helper.hovered {
  display: block;
  position: absolute;
  width: 263px;
  height: auto;
  top: 40px;
  right: 40px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  background-color: $white;
  white-space: normal;
  text-align: left;
  box-shadow: 0 0 3px $shadow;
  border-radius: 8px;

  .minimum-result-info & {
    top: 25px;
    right: 20px;
  }
}

.question-helper {
  display: none;
}

.question-helper {
  .minimum-result-info:hover & {
    display: block;
    position: absolute;
    width: 263px;
    height: auto;
    top: 30px;
    left: 0;
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    background-color: $white;
    white-space: normal;
    text-align: left;
    box-shadow: 0 0 3px $shadow;
    border-radius: 8px;
    z-index: 1;

    @media (min-width: $breakpoint-md-2) {
      left: auto;
      right: 0;
    }
  }

  .minimum-result-info & {
    top: 25px;
    right: 20px;
  }
}
