.form {
  display: grid;
  grid-template-columns: repeat(2, 499px);
  gap: 12px 32px;
  margin-bottom: 60px;
}

.label {
  position: relative;
  width: 100%;
  display: inline-block;
  height: 100%;
  padding: 16px 0 16px 56px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  border: 1px solid #e8e8e8;
  border-radius: 9px;
  border-radius: 9px;
  z-index: 2;
  transition: all 200ms ease-in;
  cursor: pointer;

  &.selected {
    border: 1px solid #0f80f1;
  }
}

.input {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: 2;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: pointer;
}
