.switch-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  visibility: hidden;
  display: none;

  &:checked ~ .switch-check:before {
    transform: translateX(16px);
  }

  &:checked ~ .switch-check {
    background-color: $blue-1;
  }
}

.switch-check {
  position: relative;
  display: block;
  width: 36px;
  height: 20px;
  background-color: $grey-1;
  cursor: pointer;
  border-radius: 31px;
  overflow: hidden;
  transition: ease-in 0.2s;

  &::before {
    content: "";
    position: absolute;
    top: 1.5px;
    left: 1.5px;
    background: $white;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    transition: 0.5s;
  }
}

.header-form {
  .switch-wrapper {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    .switch-check {
      height: 34px;
      width: 52px;
      background: #fcfcfc;
      border: 2px solid #e8e8e8;
      border-radius: 100px;
      transition: all 0.3s ease-in;
    }

    .switch-input ~ .switch-check::before {
      position: absolute;
      left: 4px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='%23929292'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.3225 6.67766C17.713 7.06818 17.713 7.70135 17.3225 8.09187L13.4141 12.0003L17.3225 15.9087C17.713 16.2993 17.713 16.9324 17.3225 17.3229C16.932 17.7135 16.2988 17.7135 15.9083 17.3229L11.9999 13.4145L8.09175 17.3226C7.70123 17.7132 7.06806 17.7132 6.67754 17.3226C6.28701 16.9321 6.28701 16.299 6.67754 15.9084L10.5857 12.0003L6.67754 8.09217C6.28701 7.70165 6.28701 7.06848 6.67754 6.67796C7.06806 6.28743 7.70123 6.28743 8.09175 6.67796L11.9999 10.5861L15.9083 6.67766C16.2988 6.28713 16.932 6.28713 17.3225 6.67766Z' fill='%23FCFCFC'/%3E%3C/svg%3E%0A");
    }

    .switch-input:checked ~ .switch-check::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(16px);
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='white'/%3E%3Cpath d='M18.0794 7.27157L18.1755 7.36482C18.5662 7.78665 18.6095 8.45546 18.2759 8.93285L18.1508 9.08549L11.0853 16.5902C10.6242 17.0801 9.89541 17.1372 9.37362 16.7267L9.22442 16.5911L5.84629 13.1072C5.39661 12.6436 5.38315 11.8809 5.81604 11.3996C6.20805 10.9638 6.84242 10.9015 7.30107 11.2517L7.44674 11.3824L10.0768 14.0953L10.2107 14.0942L16.5565 7.35424C16.9706 6.91428 17.6295 6.88115 18.0794 7.27157Z' fill='%230F80F1'/%3E%3C/svg%3E%0A");
    }

    .switch-input:checked ~ .switch-check {
      background-color: $blue-1;
    }
  }
}
