.menu {
  padding: 0 var(--main-space);
  
  @media (min-width: $breakpoint-md-2) {
    padding: 0;
  }
}

.menu-wrapper {
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  @media (min-width: $breakpoint-md-2) {
    background-color: transparent;
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    z-index: 0;
  }
  
  &.menu-open {
    height: 100vh;
    padding: 88px 0;

    @media (min-width: $breakpoint-md-2) {
      padding: 0;
      height: auto;
    }
  }

  &.menu-open {
    .menu-list {
      display: flex;
      flex-direction: column;

      @media (min-width: $breakpoint-md-2) {
        flex-direction: unset;
      }
    }
  }
}

.menu-list {
  position: relative;
  gap: 20px;
  display: none;

  @media (min-width: $breakpoint-md-2) {
    display: flex;
    gap: 16px;
  }

  .menu-item:first-child:hover .submenu {
    display: block;
  }

  .menu-item:first-child .menu-link:not(.expandable):after {
    background-color: $base;
    content: "";
    height: 6px;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-2.54846e-07 3.28929L-8.34347e-08 5.25L3 2.71071L6 5.25L6 3.28929L3 0.75L-2.54846e-07 3.28929Z' fill='%230C1628'/%3E%3C/svg%3E%0A");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: initial;
    display: inline-flex;
    width: 6px;
    rotate: 180deg;
    margin-left: 4px;
  }

  .menu-item:first-child:hover .menu-link:not(.expandable):after {
    rotate: 0deg;
  }
}

.menu-item {
  position: relative;
  cursor: pointer;

  @media (min-width: $breakpoint-md-2) {
    padding: 8px 12px;
  }
}

.header-logo-link {
  display: none;

  @media (min-width: $breakpoint-md-2) {
    display: block;
  }

  img {
    @media (min-width: $breakpoint-md-2) {
      width: 43px;
      height: 24px;
    } 
    
    @media (min-width: $breakpoint-xl) {
      width: 65px;
      height: 36px;
    } 
  }
}

#nix-link::after {
  content: none;
}

.menu-link {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  text-transform: uppercase;
  cursor: pointer;

  &:before {
    @media (min-width: $breakpoint-md-2) {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      bottom: -7px;
      position: absolute;
    }
  }

  &.expandable {
    &:after {
      content: "";
      display: inline-flex;
      background: url("data:image/svg+xml,%3Csvg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-6.20687e-07 4.05893L-3.63571e-07 7L4.5 3.19107L9 7L9 4.05893L4.5 0.25L-6.20687e-07 4.05893Z' fill='%23282828'/%3E%3C/svg%3E%0A") no-repeat;
      width: 9px;
      height: 7px;
      margin-left: 8px;
      rotate: 180deg;
      transform-origin: center center;
    }

    &.is-open:after {
      rotate: 0deg;
    }
  }

  &.menu-link-professions {
    &.mobile {
      display: flex;

      @media (min-width: $breakpoint-md-2) {
        display: none;
      }
    }

    &:not(.mobile) {
      display: none;

      @media (min-width: $breakpoint-md-2) {
        display: flex;
        align-items: center;
      }
    }
  }
  
  &.active {
    color: $blue-1;

    @media (min-width: $breakpoint-md-2) {
      color: $base;
    }

    &::before {
      background-color: $blue-1;
    }
  }

  @media (min-width: $breakpoint-md-2) {
    font-size: 18px;
    line-height: 16px;

    &:hover:not(.active):before {
      background-color: $base;
    }
  }
}

.submenu-item-icon {
  @media (min-width: $breakpoint-md-2) {
    display: none;
  }
}

.menu-bar {
  position: absolute;
  display: inline-block;
  z-index: 2;
  float: right;
  right: 14px;
  top: 17px;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 30px;
  height: 30px;

  @media (min-width: $breakpoint-md-2) {
    display: none;
  }
}

.menu-icon {
  width: 24px;
  height: 10px;
  position: relative;
  display: block;
  margin: 2px auto 0;
}

.bar {
  width: 100%;
  height: 2px;
  display: block;
  position: relative;
  background-color: $base;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);

  &.bar1 {
    -webkit-transform: translateY(-2px) rotate(0deg);
    transform: translateY(-2px) rotate(0deg);
  }

  &.bar2 {
    -webkit-transform: translateY(6px) rotate(0deg);
    transform: translateY(6px) rotate(0deg);
  }
}

.menu-open {
  .menu-bar {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .bar {
    transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: 0.1s;

    &.bar1 {
      -webkit-transform: translateY(5px) rotate(45deg);
      transform: translateY(5px) rotate(45deg);
    }

    &.bar2 {
      -webkit-transform: translateY(3px) rotate(-45deg);
      transform: translateY(3px) rotate(-45deg);
    }
  }
}

#nix-link {
  margin-top: 0;
}

.submenu-holder {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease;
  
  @media (min-width: $breakpoint-md-2) {
    padding-top: 8px;
    max-height: none;
  }
  
  &.is-open {
    max-height: 1000px;
    border-bottom: 1px solid $grey-1;
    padding: 12px 0;

    @media (min-width: $breakpoint-md-2) {
      max-height: unset;
      border-bottom: 0;
      padding: 0;
    }
  }
}

.submenu {
  @media (min-width: $breakpoint-md-2) {
    display: none;
    position: absolute;
    min-width: 147px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    padding: 6px 8px;
    border: 1px solid $grey-1;
    border-radius: 8px;
    background-color: $white;
    white-space: nowrap;
    z-index: 1;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 10px;
  }
}

.submenu-mobile-scroll {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 4px 8px;

  @media (min-width: $breakpoint-md-2) {
    font-size: 14px;
    line-height: 17.6px;
    padding: 9px 4px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 16px;
    line-height: 24px;
    padding: 6px;
  }
}

.submenu-item {
  width: 100%;
  font-size: 16px;
  line-height: 20.11px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  @media (min-width: $breakpoint-md-2) {
    font-size: 18px;
    line-height: 27px;
  }
  
  &:hover {
    background-color: $blue-2;
  }

  &.active {
    background-color: $blue-2;
  }
  
  .submenu-item-icon {
    margin-right: 6px;
  }
}
