.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px);
  text-align: center;
  padding: 24px 0;

  @media (min-width: $breakpoint-md-2) {
    height: calc(100vh - 72px);
  }
  
  &.full-screen {
    height: 100vh;

    @media (min-width: $breakpoint-xl) {
      .error-button-link {
        margin: 0;
        align-self: flex-start;
      }
    }
  }

  .error-title {
    font-size: 32px;
    line-height: 40.22px;
    text-transform: uppercase;
    margin-bottom: 8px;

    @media (min-width: $breakpoint-md-2) {
      font-size: 40px;
      line-height: 50.28px;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 64px;
      line-height: 80.45px;
    }

    @media (min-width: $breakpoint-xxl) {
      font-size: 80px;
      line-height: 100.56px;
    }
  }

  .error-subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;

    @media (min-width: $breakpoint-md-2) {
      margin-bottom: 48px;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 38px;
    }

    @media (min-width: $breakpoint-xxl) {
      font-size: 24px;
      line-height: 30.17px;
      margin-bottom: 48px;
    }
  }
  
  .error-image {
    width: 100%;
    height: auto;
    margin: 0 auto 48px;

    @media (min-width: $breakpoint-md-2) {
      width: 440px;
      margin-bottom: 64px;
    }

    @media (min-width: $breakpoint-xl) {
      width: 810px;
      margin-bottom: 58px;
    }

    @media (min-width: $breakpoint-xxl) {
      width: 733px;
      margin-bottom: 64px;
    }
  }

  .error-button-link {
    width: 100%;
    margin: 0 auto;

    @media (min-width: $breakpoint-md-2) {
      width: 440px;
    }
  }
  
  .error-wrapper {
    margin: 0 auto;

    @media (min-width: $breakpoint-md-2) {
      width: 540px;
    }
    
    @media (min-width: $breakpoint-xl) {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 48px;
      text-align: left;

      .error-image {
        width: 527px;
        margin: 0;
      }
    }
  }
}
