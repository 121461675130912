.list-option-wrapper {
  display: none;
  flex-direction: column;
  gap: 10px;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow-y: scroll;
  background-color: $white;
  border: 1px solid $grey-1;
  position: absolute;
  top: 68px;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  z-index: 9;

  @media (min-width: $breakpoint-md-2) {
    width: 100% !important;
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.list-option-title {
  display: inline-block;
  margin-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #0f80f1;
}

.specialities-control {
  position: relative;
  flex: 1;

  @media (min-width: $breakpoint-xl) {
    max-width: 512px;
  }
  
  @media (min-width: $breakpoint-xxl) {
    max-width: 580px;
  }
}

.search-control-wrapper {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 7px;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(/assets/img/arrow-2.svg);
    background-repeat: no-repeat;
    transition: all 0.2s ease;
  }

  &.isActive::after {
    transform: translateY(-50%) rotate(180deg);
  }

  &.isActive + .list-option-wrapper {
    display: flex;
    max-height: 300px;
  }
}

.list-option-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  margin-bottom: 4px;
  border-radius: 8px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  &:hover {
    background: $blue-2;
  }
}
