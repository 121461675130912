.expandable {
  .expandable-content {
    position: relative;
    max-height: 276px;
    margin-bottom: 24px;
    overflow: hidden;
    transition: max-height 0.2s ease;

    @media (min-width: $breakpoint-md-2) {
      max-height: 194px;
    }

    @media (min-width: $breakpoint-xl) {
      max-height: 233px;
      margin-bottom: 32px;
    }
    
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 44px;
      background: linear-gradient(to top, rgba(255, 255, 255, 0.94), rgba(255, 255, 255, 0));
    }
  }
  
  &.is-open {
    .expandable-content {
      max-height: unset;
      
      &:before {
        display: none;
      }
    }
  }
  
  .expandable-button {
    display: flex;
    justify-content: center;
  }
}