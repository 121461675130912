.slick-list{
  --height: 204px;

  height: var(--height);
  margin-right: calc(var(--main-space) * -1);

  @media (min-width: $breakpoint-xxl) {
    --height: 265px;
  }
}

.slick-slide {
  --width: 291px;

  width: var(--width);
  min-width: var(--width);
  margin-right: 24px;

  @media (min-width: $breakpoint-md-2) {
    --width: 285px;
  }

  @media (min-width: $breakpoint-xl) {
    --width: 389px;
    margin-right: 32px;
  }

  @media (min-width: $breakpoint-xxl) {
    --width: 498px;
    margin-right: 33px;
  }
}

.slick-track {
  display: flex;
  flex-wrap: nowrap;
}

.slider-item{
  height: var(--height);
  padding: 16px 16px 24px;
  background-color: $white;
  border: 1px solid $grey-1;
  border-radius: 16px;
  font-size: 16px;
  line-height: 24px;

  @media (min-width: $breakpoint-md-2) {
    padding: 16px 16px 20px;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 16px;
  }

  @media (min-width: $breakpoint-xxl) {
    padding: 32px;
  }

  .slider-stage-holder {
    margin-bottom: 24px;

    @media (min-width: $breakpoint-md-2) {
      margin-bottom: 16px;
    }

    @media (min-width: $breakpoint-xl) {
      margin-bottom: 24px;
    }

    @media (min-width: $breakpoint-xxl) {
      margin-bottom: 40px;
    }

    .slider-stage{
      position: relative;
      display: inline-flex;
      font-weight: 600;
      font-size: 24px;
      line-height: 31.2px;
      color: $grey-4;

      @media (min-width: $breakpoint-xl) {
        font-size: 32px;
        line-height: 41.6px;
      }
  
      &::after{
        content: "";
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        background: $grey-4;
      }
    }
  }

  .slider-date{
    font-size: 20px;
    line-height: 25.14px;
    font-weight: 400;
    margin-bottom: 12px;

    @media (min-width: $breakpoint-md-2) {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 8px;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 24px;
      line-height: 30.17px;
      margin-bottom: 12px;
    }

    @media (min-width: $breakpoint-xxl) {
      font-size: 32px;
      line-height: 40.22px;
      margin-bottom: 18px;
    }
  }

  .slider-text{
    font-size: 16px;
    line-height: 24px;

    @media (min-width: $breakpoint-md-2) {
      line-height: 19.2px;
    }

    @media (min-width: $breakpoint-xl) {
      line-height: 24px;
    }

    @media (min-width: $breakpoint-xxl) {
      font-size: 18px;
      line-height: 23.4px;
    }
  }

  &.is-disabled{
    border-color: $grey-1;
  }

  &.is-current {
    border-color: $blue-5;
    background: $blue-2;

    .slider-stage {
      color: $blue-1;
      
      &:after {
        background: $blue-1;
      }
    }
  }
  
  &.is-future {
    border-color: $blue-5;

    .slider-stage {
      color: $blue-1;
      
      &:after {
        background: $blue-1;
      }
    }
  }
}

.slider {
  margin-bottom: 8px;

  @media (min-width: $breakpoint-md-2) {
    margin-bottom: 16px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-bottom: 32px;
  }

  @media (min-width: $breakpoint-xxl) {
    margin-bottom: 40px;
  }

  .slider-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 56px;
    margin-bottom: 24px;

    @media (min-width: $breakpoint-md-2) {
      margin-bottom: 10px;
    }

    @media (min-width: $breakpoint-xl) {
      margin-bottom: 32px;
    }

    @media (min-width: $breakpoint-xxl) {
      margin-bottom: 53px;
    }
    
    .slider-title {
      font-size: 24px;
      line-height: 30.17px;

      @media (min-width: $breakpoint-md-2) {
        font-size: 28px;
        line-height: 36.4px;
      }

      @media (min-width: $breakpoint-xl) {
        font-size: 40px;
        line-height: 52px;
      }

      @media (min-width: $breakpoint-xxl) {
        font-size: 48px;
        line-height: 62.4px;
      }
    }
    
    .slider-controls {
      display: flex;
      align-items: center;
      gap: 8px;
      
      .slider-control {
        --size: 48px;

        position: relative;
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        cursor: pointer;

        @media (min-width: $breakpoint-md-2) {
          --size: 64px;
        }
        
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: $grey-4;
          width: 12px;
          height: 17px;
          mask-image: url("data:image/svg+xml,%3Csvg width='12' height='17' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.8' d='M6.77143 0.0605475L12 0.0605481L5.22857 8.06054L12 16.0605L6.77143 16.0605L7.94782e-07 8.06054L6.77143 0.0605475Z' fill='%23A0A6AF'/%3E%3C/svg%3E%0A");
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;

          @media (min-width: $breakpoint-md-2) {
            width: 18px;
            height: 24px;
          }
        }
        
        &:hover {
          box-shadow: 0 8px 20px $grey-5;

          &:before {
            background: $blue-1;
          }
        }
        
        &.slider-control-next {
          &:before {
            transform: translate(-50%, -50%) rotate(180deg);
            transform-origin: center center; 
          }
        }
      }
    }
  }
 }

.slick-arrow{
  display: none !important;
}
