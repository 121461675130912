.about-main {
  margin-bottom: 64px;

  @media (min-width: $breakpoint-md-2) {
    margin-bottom: 100px;
  }

  @media (min-width: $breakpoint-xxl) {
    margin-bottom: 120px;
  }

  @media (min-width: $breakpoint-xxl) {
    margin-bottom: 160px;
  }
}

.about-header {
  padding-top: 24px;
  margin-bottom: 64px;

  @media (min-width: $breakpoint-md-2) {
    padding-top: 16px;
    margin-bottom: 100px;
  }

  @media (min-width: $breakpoint-xl) {
    padding-top: 48px;
    margin-bottom: 120px;
  }

  @media (min-width: $breakpoint-xxl) {
    padding-top: 111px;
    margin-bottom: 160px;
  }

  .about-header-wrapper {
    @media (min-width: $breakpoint-md-2) {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    
    @media (min-width: $breakpoint-xl) {
      gap: 32px;
    }
    
    @media (min-width: $breakpoint-xxl) {
      gap: 63px;
    }
  }
  
  .about-wrapper-inner {
    @media (min-width: $breakpoint-md-2) {
      flex: 1;
    }
    
    .title-arrow {
      @media (min-width: $breakpoint-md-2) {
        margin: 0 0 16px;
      }

      @media (min-width: $breakpoint-xl) {
        width: 40px;
        height: 30px;
        margin-bottom: 32px;
      }
    }
  }
  
  .about-header-title {
    font-size: 32px;
    line-height: 40.22px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 24px;
    text-transform: uppercase;

    @media (min-width: $breakpoint-md-2) {
      font-size: 40px;
      line-height: 50.28px;
      text-align: left;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 64px;
      line-height: 80.45px;
    }

    @media (min-width: $breakpoint-xxl) {
      font-size: 80px;
      line-height: 100.56px;
      margin-bottom: 40px;
    }
  }
  
  .about-header-image {
    margin-bottom: 24px;

    @media (min-width: $breakpoint-md-2) {
      margin-bottom: 0;
      width: 525px;
    }

    @media (min-width: $breakpoint-xl) {
      width: 641px;
    }

    @media (min-width: $breakpoint-xxl) {
      width: 848px;
    }
    
    &.mobile {
      display: block;

      @media (min-width: $breakpoint-md-2) {
        display: none;
      }
    }
    
    &.desktop {
      display: none;

      @media (min-width: $breakpoint-md-2) {
        display: block;
      }
    }
    
    img {
      width: 100%;
      height: auto;
    }
  }
  
  .about-header-text {
    font-size: 16px;
    line-height: 24px;

    @media (min-width: $breakpoint-xl) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (min-width: $breakpoint-xxl) {
      line-height: 30.17px;
    }
  }
}

.about-cards {
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media (min-width: $breakpoint-md-2) {
    gap: 64px;
  }

  @media (min-width: $breakpoint-xxl) {
    gap: 100px;
  }
  
  .about-info-title {
    font-size: 24px;
    line-height: 30.17px;
    text-align: center;
    margin-bottom: 16px;

    @media (min-width: $breakpoint-md-2) {
      text-align: left;
      font-size: 28px;
      line-height: 36.4px;
      margin-bottom: 12px;
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 24px;
    }

    @media (min-width: $breakpoint-xxl) {
      font-size: 48px;
      line-height: 62.4px;
      margin-bottom: 32px;
    }
  }
  
  .about-info-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    line-height: 17.6px;

    @media (min-width: $breakpoint-xl) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (min-width: $breakpoint-xxl) {
      font-size: 18px;
      line-height: 22.63px;
    }
  }
  
  .about-info-img-holder {
    position: relative;
    padding: 0 13px;
    margin-bottom: 24px;

    @media (min-width: $breakpoint-md-2) {
      margin: 0;
    }

    &.mobile {
      display: block;

      @media (min-width: $breakpoint-md-2) {
        display: none;
      }
    }

    &.desktop {
      display: none;

      @media (min-width: $breakpoint-md-2) {
        display: block;
      }
    }
    
    .about-info-img-wrapper {
      position: relative;
      
      &:before {
        content: '';
        position: absolute;
        border-radius: 16px;
        background: $blue-6;
        width: 100%;
        height: 100%;
      }
    }
  
    img {
      position: relative;
      width: 100%;
      height: auto;
      border-radius: 16px;

      @media (min-width: $breakpoint-md-2) {
        width: 321px;
      }

      @media (min-width: $breakpoint-xl) {
        width: 531px;
      }

      @media (min-width: $breakpoint-xxl) {
        width: 631px;
      }
    }
    
    .about-image-decoration {
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 1;
    }
    
    .about-image-decoration-1 {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.945144 2.0061L9.7579 9.46508L10.0809 9.73848L10.4039 9.46508L19.2158 2.00689L19.2158 6.99343L10.0802 14.7258L0.945143 6.99344L0.945144 2.0061Z' fill='%2300AEEF' fill-opacity='0.1' stroke='%2300AEEF'/%3E%3C/svg%3E%0A");
      width: 20px;
      height: 16px;

      @media (min-width: $breakpoint-xl) {
        width: 33.71px;
        height: 25.28px;
      }
    }
    
    .about-image-decoration-2 {
      background-image: url("data:image/svg+xml,%3Csvg width='41' height='32' viewBox='0 0 41 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-0.00487366 17.7697L-0.00487249 31.2042L20.4712 13.8056L40.9453 31.2025L40.9453 17.7696L20.4695 0.371096L-0.00487366 17.7697Z' fill='%23E60036' fill-opacity='0.1'/%3E%3Cpath d='M0.495127 30.1232L20.1474 13.4246L20.4712 13.1495L20.7949 13.4246L40.4453 30.1215L40.4453 18.0009L20.4695 1.02723L0.495126 18.0009L0.495127 30.1232Z' stroke='%23ED1847' stroke-opacity='0.2'/%3E%3C/svg%3E%0A");
      width: 41px;
      height: 32px;

      @media (min-width: $breakpoint-xl) {
        width: 71.63px;
        height: 53.94px;
      }
    }
    
    .about-image-decoration-3 {
      background-image: url("data:image/svg+xml,%3Csvg width='41' height='31' viewBox='0 0 41 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.551768 29.8283L20.204 13.1297L20.5278 12.8546L20.8516 13.1297L40.502 29.8266L40.502 17.706L20.5262 0.732311L0.551767 17.706L0.551768 29.8283Z' fill='%2300AEEF' fill-opacity='0.1' stroke='%2300AEEF'/%3E%3C/svg%3E%0A");
      width: 41px;
      height: 32px;

      @media (min-width: $breakpoint-xl) {
        width: 71.63px;
        height: 53.94px;
      }
    }

    .about-image-decoration-4 {
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='29' viewBox='0 0 21 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.08368 1.35156L12.2364 14.5985L12.5075 14.9206L12.2364 15.2426L1.08483 28.4883H8.92257L20.3464 14.9195L8.92258 1.35156H1.08368Z' fill='%2300AEEF' fill-opacity='0.1' stroke='%2300AEEF'/%3E%3C/svg%3E%0A");
      width: 21px;
      height: 29px;

      @media (min-width: $breakpoint-xl) {
        width: 36.72px;
        height: 49.22px;
      }
    }
  }

  &>li {
    @media (min-width: $breakpoint-md-2) {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    @media (min-width: $breakpoint-xl) {
      gap: 32px;
    }

    @media (min-width: $breakpoint-xxl) {
      gap: 84px;
    }

    &:nth-child(1) {
      .about-info-img-holder {
        padding-top: 20px;
        padding-bottom: 19px;

        @media (min-width: $breakpoint-xl) {
          padding: 42px 32px 71px 36px;
        }
      }

      .about-image-decoration-1 {
        top: 0;
        right: 18.29px;
      }
      
      .about-image-decoration-2 {
        bottom: 0;
        left: 0;
      }

      .about-info-img-wrapper:before {
        left: 10px;
        top: 10px;
        rotate: 4deg;
      }
    }
    
    &:nth-child(2) {
      
      .about-card-holder {
        @media (min-width: $breakpoint-md-2) {
          order: -1;
        }
      }

      .about-info-img-holder {
        padding-bottom: 18.4px;

        @media (min-width: $breakpoint-xl) {
          padding-bottom: 40px;
        }
      }

      .about-image-decoration-3 {
        bottom: 0;
        right: 0;
      }

      .about-info-img-wrapper:before {
        left: 10px;
        top: 10px;
        rotate: -4deg;
      }
    }
    
    &:nth-child(3) {
      padding-left: var(--image-padding);

      .about-info-img-holder {
        padding-bottom: 18.4px;

        @media (min-width: $breakpoint-xl) {
          padding: 64px 70px 66px 36px;
        }
      }

      .about-image-decoration-2 {
        bottom: 0;
        right: 0;
      }

      .about-image-decoration-4 {
        left: 0;
        top: 19.55px;
      }

      .about-info-img-wrapper:before {
        left: 10px;
        top: 10px;
        rotate: -4deg;
      }
    }
  }
}

.about-text {
  font-size: 18px;
  line-height: 26px;
  flex: 1 1 50%;

  a {
    color: $accent;
  }

  a:hover {
    color: $accent-hover;
  }
}

.more-info {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 64px;

  @media (min-width: $breakpoint-md-2) {
    font-size: 18px;
    line-height: 22.01px;
    margin-bottom: 100px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-bottom: 120px;
  }

  @media (min-width: $breakpoint-xxl) {
    margin-bottom: 160px;
  }

  .more-info-link {
    color: $blue-1;
  }
}