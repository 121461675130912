.header-nav-wrapper {
  position: sticky;
  width: 100%;
  background-color: $main-bg-color;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s ease-out, background 1s ease-out;
  width: 100%;
  margin: 0 auto;

  &.menu-open {
    height: 100%;
    background: #111;
    transition: all 0.45s ease-out, background 0.8s ease-out;
  }
}

.header-logo {
  width: 104px;
  height: 32px;

  @media (min-width: $breakpoint-md-2) {
    width: 78px;
    height: 24px;
  }
  
  @media (min-width: $breakpoint-xl) {
    width: 104px;
    height: 32px;
  }
}

.header-logo-itsway {
  display: flex;
  z-index: 9;
}

.header-content {
  margin-bottom: 64px;

  @media (min-width: $breakpoint-md-2) {
    margin-bottom: 106px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-bottom: 131px;
  }

  @media (min-width: $breakpoint-xxl) {
    margin-bottom: 163px;
  }

  .header-holder {
    position: relative;

    @media (min-width: $breakpoint-md-2) {
      display: flex;
    }

    .header-img {
      width: 100%;
      height: auto;
      margin-bottom: 32px;

      @media (min-width: $breakpoint-md-2) {
        width: 470px;
        position: absolute;
        right: 0;
        bottom: 12.8px;
        margin: 0;
      }
      
      @media (min-width: $breakpoint-xl) {
        width: 633px;
        bottom: -11px;
      }
      
      @media (min-width: $breakpoint-xxl) {
        width: 722px;
        bottom: auto;
        top: 0;
      }
    }

    .header-form {
      position: relative;
      padding: 32px 16px;
      background-color: $white;
      border-radius: 20px;
      border: 1px solid $grey-1;
      z-index: 1;

      @media (min-width: $breakpoint-md-2) {
        order: -1;
        width: 450px;
        padding: 20px 16px;
      }

      @media (min-width: $breakpoint-xl) {
        width: 576px;
        padding: 20px 24px;
      }

      @media (min-width: $breakpoint-xxl) {
        width: 709px;
        padding: 40px 48px;
      }
    }
  }
}

.header-info {
  
}

.header-subtitle-holder {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;

  @media (min-width: $breakpoint-md-2) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
  }

  @media (min-width: $breakpoint-xl) {
    top: 50%;
    transform: translateY(-50%);
    padding-top: 0;
  }
}

.header-subtitle {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background: $blue-1;
  border-radius: 12px;
  color: $white;
  padding: 10px 11px;
  height: 44px;
  font-size: 16px;
  line-height: 24px;

  &::before {
    content: "";
    position: absolute;
    left: 17px;
    top: -8px;
    width: 15px;
    height: 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.61538 11.9997C5.76923 5.44251 0 0.0764647 0 0.0764647C0 0.0764647 12.1154 -1.71112 15 12' fill='%2300AEEF'/%3E%3C/svg%3E%0A");
  }
}

.header-form-title {
  font-size: 24px;
  line-height: 30.17px;
  font-weight: 400;
  margin-bottom: 8px;

  @media (min-width: $breakpoint-xl) {
    font-size: 24px;
    line-height: 30.17px;
    margin-bottom: 12px;
  }
}

.header-form-subtitle {
  font-size: 12px;
  line-height: 15.08px;
  margin-bottom: 18px;
  position: relative;
  padding-left: 15px;
  
  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.69746 4.941L4.97996 5.15625L4.91846 5.44125L5.25596 5.5035C5.47646 5.556 5.51996 5.6355 5.47196 5.85525L4.91846 8.45625C4.77296 9.129 4.99721 9.4455 5.52446 9.4455C5.93321 9.4455 6.40796 9.2565 6.62321 8.997L6.68921 8.685C6.53921 8.817 6.32021 8.8695 6.17471 8.8695C5.96846 8.8695 5.89346 8.72475 5.94671 8.46975L6.69746 4.941ZM6.74996 3.375C6.74996 3.57391 6.67095 3.76468 6.53029 3.90533C6.38964 4.04598 6.19888 4.125 5.99996 4.125C5.80105 4.125 5.61029 4.04598 5.46963 3.90533C5.32898 3.76468 5.24996 3.57391 5.24996 3.375C5.24996 3.17609 5.32898 2.98532 5.46963 2.84467C5.61029 2.70402 5.80105 2.625 5.99996 2.625C6.19888 2.625 6.38964 2.70402 6.53029 2.84467C6.67095 2.98532 6.74996 3.17609 6.74996 3.375Z' fill='%2300AEEF'/%3E%3Ccircle cx='6' cy='6' r='5.5' stroke='%2300AEEF'/%3E%3C/svg%3E%0A") no-repeat;
    background-size: contain;
    width: 12px;
    height: 12px;

    @media (min-width: $breakpoint-md-2) {
      top: 1px;
      width: 16px;
      height: 16px;
    }
  }

  @media (min-width: $breakpoint-md-2) {
    font-size: 14px;
    line-height: 17.6px;
    padding-left: 24px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-bottom: 20px;
  }

  @media (min-width: $breakpoint-xxl) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 28px;
  }
}

.header-prompt-title {
  font-size: 16px;
  line-height: 24px;
}

.header-prompt {
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: 8px;
  padding: 48px 16px 24px;
  background-color: $white;
  border: 1px solid $grey-1;
  border-radius: 8px;
  font-size: 14px;
  line-height: 17.6px;
  text-align: left;
  z-index: 2;

  @media (min-width: $breakpoint-md-2) {
    right: 0;
    width: 568px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 16px;
    line-height: 24px;
    width: 631px;
    padding: 48px 24px 32px;
  }

  .items-list li {
    font-size: inherit;
    line-height: inherit;
  }
}

.header-prompt-detail {
  position: relative;
  display: block;
  margin-top: 16px;
  font-size: 12px;
  line-height: 15.08px;
  padding-left: 16px;

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
    line-height: 17.6px;
    margin-top: 32px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.688 6.2C6.18667 5.848 5.68533 5.496 5.184 5.144C4.688 4.792 4.18667 4.44 3.68 4.088C3.136 3.81067 2.58933 3.53333 2.04 3.256C1.49067 2.97333 0.944 2.696 0.4 2.424L1.08 1.256C1.58667 1.608 2.088 1.96 2.584 2.312C3.08533 2.664 3.58933 3.01867 4.096 3.376C4.64 3.648 5.18667 3.92533 5.736 4.208C6.28533 4.48533 6.832 4.76267 7.376 5.04L6.688 6.2ZM1.08 6.2L0.4 5.04C0.944 4.75733 1.49067 4.47733 2.04 4.2C2.58933 3.92267 3.136 3.64533 3.68 3.368C4.18667 3.01067 4.688 2.65867 5.184 2.312C5.68533 1.96 6.18667 1.608 6.688 1.256L7.376 2.424C6.82667 2.696 6.27733 2.97333 5.728 3.256C5.184 3.53333 4.64 3.808 4.096 4.08C3.59467 4.432 3.09333 4.78667 2.592 5.144C2.09067 5.496 1.58667 5.848 1.08 6.2ZM3.208 7.4C3.25067 6.78667 3.29333 6.176 3.336 5.568C3.384 4.95467 3.42933 4.33867 3.472 3.72C3.42933 3.112 3.384 2.50133 3.336 1.888C3.29333 1.27467 3.25067 0.664 3.208 0.0559998H4.568C4.52 0.664 4.47467 1.27467 4.432 1.888C4.38933 2.50133 4.34667 3.112 4.304 3.72C4.34667 4.33867 4.38933 4.95467 4.432 5.568C4.48 6.176 4.52533 6.78667 4.568 7.4H3.208Z' fill='%230F80F1'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
  }
}

.header-prompt-detail-title {
  margin-bottom: 16px;

  @media (min-width: $breakpoint-xl) {
    margin-bottom: 32px;
  }
}

.header-prompt-detail-item {
  position: relative;
  padding-left: 28px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #282828;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 8px;
    height: 11px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.26786 0L0 1.42843e-07L4.23214 5L4.37114e-07 10H3.26786L7.5 5L3.26786 0Z' fill='%230F80F1'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
  }
}
