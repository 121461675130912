.is-hide{
  display: none;
}

.is-bold{
  font-weight: 600;
}

.p-0{
  padding: 0;
}
