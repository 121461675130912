.button {
  display: inline-block;
  width: 100%;
  height: 64px;
  margin-top: auto;
  background-color: $accent;
  color: $white;
  font-weight: 600;
  outline: none;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(15, 128, 241, 0.1);
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
  }

  &:hover{
    background-color: $accent-hover;
  }

  .header-form &{
    font-size: 18px;
    line-height: 22px;
  }

  .modal-wrapper &{
    flex: 0 1 366px;
    width: 366px;
    margin-top: unset;
    color: $blue-1;
    background-color: $white;
    box-shadow: none;
    border: 1px solid $blue-1;
    transition: all .3s ease;

    &:hover{
      background-color: $blue-1;
      color: $white;
    }
  }
}

.button-search {
  width: 22px;
  height: 18px;
  border: none;
  outline: none;
  background-color: transparent;
}

.button-filter {
  --size: 52px;
  
  display: flex;
  width: var(--size);
  height: var(--size);
  border-radius: 8px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='18' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.4273 10.2379H5.57013C4.82727 10.2379 4.33203 9.74267 4.33203 8.99981C4.33203 8.25696 4.82727 7.76172 5.57013 7.76172H20.4273C21.1701 7.76172 21.6654 8.25696 21.6654 8.99981C21.6654 9.74267 21.1701 10.2379 20.4273 10.2379Z' fill='%23282828'/%3E%3Cpath d='M16.7135 17.6676H9.28497C8.54211 17.6676 8.04688 17.1724 8.04688 16.4295C8.04688 15.6866 8.54211 15.1914 9.28497 15.1914H16.7135C17.4564 15.1914 17.9516 15.6866 17.9516 16.4295C17.9516 17.1724 17.4564 17.6676 16.7135 17.6676Z' fill='%23282828'/%3E%3Cpath d='M24.141 2.80822H1.85528C1.11243 2.80822 0.617188 2.31298 0.617188 1.57013C0.617188 0.827269 1.11243 0.332031 1.85528 0.332031H24.141C24.8839 0.332031 25.3791 0.827269 25.3791 1.57013C25.3791 2.31298 24.8839 2.80822 24.141 2.80822Z' fill='%23282828'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid $grey-1;

  @media (min-width: $breakpoint-xl) {
    --size: 56px;
  }
  
  &:hover {
    border-color: $grey-4;
  }

  &.is-focus{
    border-color: $blue-1;
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='18' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.4273 10.2379H5.57013C4.82727 10.2379 4.33203 9.74267 4.33203 8.99981C4.33203 8.25696 4.82727 7.76172 5.57013 7.76172H20.4273C21.1701 7.76172 21.6654 8.25696 21.6654 8.99981C21.6654 9.74267 21.1701 10.2379 20.4273 10.2379Z' fill='%2300AEEF'/%3E%3Cpath d='M16.7135 17.6676H9.28497C8.54211 17.6676 8.04688 17.1724 8.04688 16.4295C8.04688 15.6866 8.54211 15.1914 9.28497 15.1914H16.7135C17.4564 15.1914 17.9516 15.6866 17.9516 16.4295C17.9516 17.1724 17.4564 17.6676 16.7135 17.6676Z' fill='%2300AEEF'/%3E%3Cpath d='M24.141 2.80822H1.85528C1.11243 2.80822 0.617188 2.31298 0.617188 1.57013C0.617188 0.827269 1.11243 0.332031 1.85528 0.332031H24.141C24.8839 0.332031 25.3791 0.827269 25.3791 1.57013C25.3791 2.31298 24.8839 2.80822 24.141 2.80822Z' fill='%2300AEEF'/%3E%3C/svg%3E%0A");
  }
}

.button-close {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: $blue-2;
  border: none;
  border-radius: 50%;
  top: 16px;
  right: 16px;
}

.button-star {
  position: relative;
  display: flex;
  border: 0;
  background: none;
}

.button-sort {
  display: flex;
  height: 4px;
  background-color: transparent;
  border: none;

  .degree-controls &{
    height: 4px;
  }
}

.button-question {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  background-color: transparent;
  border: none;
}

.button-more {
  background-color: transparent;
  color: $blue-1;
  border: 1px solid $blue-1;
  font-size: 16px;
  line-height: 20.11px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 0;
  border-radius: 8px;
  white-space: nowrap;
  width: 100%;

  @media (min-width: $breakpoint-md-2) {
    width: auto;
    padding-left: 88px;
    padding-right: 88px;
  }
  
  &.button-expandable {
    &:after {
      content: '';
      display: inline-flex;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.72656 1.42383L8.99929 8.69656L16.272 1.42383' stroke='%2300AEEF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      width: 18px;
      height: 10px;
      margin-left: 10.73px;
    }
    
    &.expanded:after {
      rotate: 180deg;
      transform-origin: center;
    }
  }
}

.filter-wrapper{
  position: relative;
  
  &.has-parent {
    position: static;
  }
}

.button-link {
  position: relative;
  height: 52px;
  background-color: $red-1;
  font-weight: 600;
  font-size: 16px;
  line-height: 20.11px;
  text-transform: uppercase;
  border-radius: 8px;
  color: $white;
  outline: none;
  border: none;
  cursor: pointer;
  overflow: hidden;

  @media (min-width: $breakpoint-xl) {
    height: 64px;
  }
  
  &::before {
    position: absolute;
    content: "";
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
    animation: shiny-btn1 3s ease-in-out infinite;
  }
  
  &:disabled {
    background: $grey-1;
    cursor: default;
  }
  
  &.full-width {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  
  &.responsive {
    width: 100%;
    padding: 0 32px;

    @media (min-width: $breakpoint-md-2) {
      width: auto;
      padding: 0 56px;
    }
    
    @media (min-width: $breakpoint-xl) {
      padding: 0 146px;
    }
  }
}

.modal-close {
  width: 10px;
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.38477 1.38477L10.6155 10.6155' stroke='%23282828' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M1.38477 10.6157L10.6155 1.38495' stroke='%23282828' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
}
